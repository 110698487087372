const UserRole = class {
  static displayString(userRoleValue) {
    switch (userRoleValue) {
      case UserRole.SuperAdmin:
        return 'Super Admin';
      case UserRole.TailwndAdmin:
        return "RM Admin";
      case UserRole.School:
        return 'School';
      case UserRole.Teacher:
        return 'Teacher';
      case UserRole.Student:
        return 'Student';
      case UserRole.Operation:
        return 'Operation';
      case UserRole.Parent:
        return 'Parent';
      case UserRole.Trainer:
        return 'Trainer'
      case UserRole.Coordinator:
        return 'Coordinator';
      case UserRole.TeachingPlanAdmin:
        return 'TeachingPlanAdmin';
      case UserRole.Principal:
        return "Principal";
      case UserRole.VicePrincipal:
        return "VicePrincipal";
      case UserRole.ManagementUser:
        return "ManagementUser";
      case UserRole.RelationshipManagerAdmin:
        return "Relationship Manager Admin";
      case UserRole.WorkshopHead:
        return "Workshop Head";
      default:
        return '';
    }
  }

  static displayEnumId(userRoleString) {
    switch (userRoleString) {
      case 'Super Admin':
        return UserRole.SuperAdmin;
      case 'RM Admin':
       return UserRole.TailwndAdmin;
      case 'School':
        return UserRole.School;
      case 'Teacher':
        return UserRole.Teacher;
      case 'Student':
        return UserRole.Student;
      case 'Operation':
        return UserRole.Operation;
      case 'Parent':
        return UserRole.Parent;
      case 'Trainer':
        return UserRole.Trainer;
      case 'Coordinator':
        return UserRole.Coordinator;
      case 'TeachingPlanAdmin':
        return UserRole.TeachingPlanAdmin;
      case "Principal":
        return UserRole.Principal;
      case "VicePrincipal":
        return UserRole.VicePrincipal;
      case "ManagementUser":
        return UserRole.ManagementUser;
      case "Workshop Head":
        return UserRole.WorkshopHead;
      default:
        return '';
    }
  }

  static getList() {
    return [
      UserRole.SuperAdmin,
      UserRole.School,
      UserRole.Teacher,
      UserRole.Student,
      UserRole.Operation,
      UserRole.Parent,
      UserRole.Trainer,
      UserRole.Coordinator,
      UserRole.TeachingPlanAdmin,
      UserRole.Principal,
      UserRole.VicePrincipal,
      UserRole.ManagementUser,
      UserRole.TailwndAdmin,
      UserRole.RelationshipManagerAdmin,
      UserRole.WorkshopHead,
    ];
  }
};
UserRole.SuperAdmin = 0;
UserRole.School = 1;
UserRole.Teacher = 2;
UserRole.Student = 3;
UserRole.Operation = 4;
UserRole.Parent = 5;
UserRole.Trainer = 6;
UserRole.Coordinator = 7;
UserRole.TeachingPlanAdmin = 8;
UserRole.Principal = 9;
UserRole.VicePrincipal = 10;
UserRole.ManagementUser = 11;
UserRole.TailwndAdmin = 12;
UserRole.RelationshipManagerAdmin = 13;
UserRole.WorkshopHead = 14;

module.exports = UserRole;
